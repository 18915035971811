//Poster service
//This service is responsible for all poster related requests
import Api from '@/services/Api'

export default {
    /**
     * Get all design
     * @returns {Promise}
     **/
    getDesigns(params = {}) {
        return Api().get('designs', {
          params: {
            page: params.page,
            limit: params.limit,
            dateFrom: params.dateFrom,
            dateTo: params.dateTo,
            sku: params.sku,
            sort: params.sort
          }
        })
      },
    /**
     * Delete a design
     * 
     * @param {String} id
     * @returns {Promise}
     **/
    deleteDesign(id) {
        return Api().delete(`designs/${id}`)
    },


    /**
     * Get Orders
     **/
    getOrders() {
        return Api().get('orders')
    },

    /**
     * Get order by Id
     */
    getOrder(orderId) {
        return Api().get(`orders/${encodeURIComponent(orderId)}`)
    },

    getDesign(designId) {
        return Api().get(`designs/${encodeURIComponent(designId)}`);
    },

    saveDesign(designId, design) {
        return Api().put(`designs/${encodeURIComponent(designId)}`, design);
    },

    importOrder(orderId) {
        return Api().post(`orders/${encodeURIComponent(orderId)}/import`)
    },


    /**
     * Generate Order
     */
    generateOrder(designId) {
        return Api().post(`orders/generate/${encodeURIComponent(designId)}`)
    }

   
}